<template>
  <v-form
    id="filterForm"
    ref="filterForm"
    lazy-validation
    @submit.prevent=""
    autocomplete="off"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        :class="{
          'col-md-4': showFilter,
          'col-md-6 col-lg-3': !showFilter
        }"
      >
        <v-text-field
          v-model="filter.okpo"
          id="filterOkpo"
          :label="$i18n.t('label.okpo')"
          maxlength="10"
          color="privat"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="showFilter">
        <v-select
          v-model="filter.orgId"
          id="filterOrg"
          :items="optionsCompanies"
          item-text="orgName"
          item-value="orgId"
          :label="$i18n.t('label.company')"
          color="privat"
          outlined
          dense
          :disabled="filter.cpType === 'PB'"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="showFilter">
        <v-select
          v-model="filter.storeId"
          id="filterStore"
          :items="optionsStores"
          item-text="storeName"
          item-value="storeId"
          :label="$i18n.t('label.stores')"
          color="privat"
          outlined
          dense
          :disabled="!filter.orgId || !optionsStores.length"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        :class="{
          'col-md-4': showFilter,
          'col-md-6 col-lg-3': !showFilter
        }"
      >
        <v-menu
          v-model="showDatePiker"
          :close-on-content-click="false"
          :return-value.sync="filterDate"
          transition="scale-transition"
          offset-y
          max-width="290"
          ref="datePicker"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="computedDateFormattedMoment"
              :label="
                filterDate
                  ? $i18n.t('label.periodFromTo')
                  : $i18n.t('label.period')
              "
              append-icon="mdi-calendar"
              clearable
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              @click:clear="clearDate"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterDate"
            id="filterDate"
            :max="maxDate"
            :locale="langDatePiker"
            :first-day-of-week="1"
            color="privat"
            range
            no-title
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="clearDate">
              {{ $t("action.cancel") }}
            </v-btn>
            <v-btn text color="primary" @click="saveDate">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        :class="{
          'col-md-4': showFilter,
          'col-md-6 col-lg-3': !showFilter
        }"
      >
        <v-select
          v-model="filter.state"
          id="filterState"
          :items="options.states"
          item-text="label"
          item-value="value"
          :label="$i18n.t('label.state')"
          color="privat"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        :class="{
          'col-md-4': showFilter,
          'col-md-6 col-lg-3': !showFilter
        }"
      >
        <v-select
          v-model="filter.objectDetailId"
          id="filterSubject"
          :items="options.subjects"
          item-text="nameDesc"
          item-value="id"
          :label="$i18n.t('label.leasingSubject')"
          color="privat"
          outlined
          dense
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="3" v-if="isCpTypeFilter">
        <v-select
          v-model="filter.cpType"
          id="filterCpType"
          :items="optionsTypes"
          item-text="label"
          item-value="value"
          :label="$i18n.t('label.orders')"
          color="privat"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-if="isCpTypeFilter">
        <v-select
          v-model="filter.program"
          id="filterCpType"
          :items="optionsPrograms"
          item-text="label"
          item-value="value"
          :label="$i18n.t('label.financingProgram')"
          color="privat"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="d-flex justify-space-between"
        :class="{
          'col-md-4': !showFilter,
          'col-sm-6': showFilter
        }"
      >
        <v-btn
          class="mr-5"
          width="39%"
          height="39px"
          elevation="1"
          @click="filterClear"
        >
          {{ $t("action.clear") }}
        </v-btn>
        <v-btn
          color="privat"
          width="55%"
          height="39px"
          elevation="1"
          dark
          @click="filterOrders"
        >
          {{ $t("action.filter") }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        :class="{
          'col-sm-6 col-md-7 offset-md-1': !showFilter,
          'col-sm-6 col-md-12': showFilter
        }"
      >
        <BaseSearch @search="searchUp" :label="`searchByTable`" />
      </v-col>
    </v-row>

    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </v-form>
</template>

<script>
import moment from "moment";
import CONST from "@/utils/constants";
import { mapGetters } from "vuex";
import BaseSearch from "@/components/BaseSearch";
import showSnack from "@/mixins/showSnack.mixins";

export default {
  name: "OrdersFilter",
  props: {
    options: Object
  },
  mixins: [showSnack],
  components: {
    BaseSearch
  },
  data() {
    return {
      filter: {
        okpo: null,
        orgId: null,
        storeId: null,
        objectDetailId: null,
        from: null,
        to: null,
        state: null,
        cpType: "PB",
        program: null
      },
      filterDate: null,
      maxDate: moment().format("YYYY-MM-DD"),
      showDatePiker: false,
      langDatePiker: null,
      showClearBtn: false
    };
  },
  computed: {
    ...mapGetters(["getUser", "getPointOfEntry"]),
    optionsCompanies() {
      if (this.getPointOfEntry && this.filter.cpType === "PB") {
        return [];
      }
      return this.options.companies;
    },
    optionsStores() {
      if (this.getPointOfEntry && this.filter.cpType === "PB") {
        return [];
      }

      if (this.filter.orgId) {
        let company = this.options.companies.find(
          el => el.orgId === this.filter.orgId
        );
        return company.storeFilterSet;
      }
      return [];
    },
    optionsTypes() {
      return [
        { label: this.$i18n.t("options.cpType.dealer"), value: "DEALER" },
        { label: this.$i18n.t("options.cpType.employee"), value: "PB" }
      ];
    },
    optionsPrograms() {
      return [
        {
          value: "FL",
          label: this.$i18n.t("options.allTypeFinancing.FL")
        },
        {
          value: "FL_579",
          label: this.$i18n.t("options.allTypeFinancing.FL_579")
        },
        {
          value: "CR",
          label: this.$i18n.t("options.allTypeFinancing.CR")
        },
        {
          value: "CR_579",
          label: this.$i18n.t("options.allTypeFinancing.CR_579")
        }
      ];
    },
    computedDateFormattedMoment() {
      let from = null;
      let to = null;
      let date = null;

      if (this.filterDate && this.filterDate.length === 2) {
        from = moment(this.filterDate[0]).format("DD.MM.YYYY");
        to = moment(this.filterDate[1]).format("DD.MM.YYYY");
        date = `${from} ~ ${to}`;
      }

      return date ? date : null;
    },
    isCEO() {
      let role = this.getUser?.role?.value;

      return role === "HEAD" || role === "SUB_HEAD";
    },
    isAdmin() {
      return this.getUser?.userGrants?.includes("ADMINISTRATOR");
    },
    showFilter() {
      return !!(this.isAdmin || this.isCEO);
    },
    isCpTypeFilter() {
      return this.getUser?.isCpTypeFilterView;
    }
  },
  methods: {
    saveDate() {
      if (this.filterDate) {
        if (this.filterDate.length === 1) {
          this.filterDate = this.filterDate[0];
          this.showSnack("error", [this.$i18n.t("error.WRONG_CHOOSE_DATE")]);
          return;
        }
        if (this.filterDate[0] > this.filterDate[1]) {
          this.filter.from = this.filterDate[1];
          this.filter.to = this.filterDate[0];
        } else {
          this.filter.from = this.filterDate[0];
          this.filter.to = this.filterDate[1];
        }

        this.$refs.datePicker.save(this.filterDate);
      }
    },
    clearDate() {
      this.filterDate = null;
      this.filter.from = null;
      this.filter.to = null;
      this.showDatePiker = false;
    },
    filterClear() {
      this.filter = {
        okpo: null,
        orgId: null,
        storeId: null,
        objectDetailId: null,
        from: null,
        to: null,
        state: null,
        cpType: "PB",
        program: null
      };
      this.filterDate = null;
    },
    filterOrders() {
      let filter = this.filter;
      let sendData = {};

      if (filter.cpType === "PB") {
        filter.orgId = null;
        filter.storeId = null;
        filter.storeIdList = null;
      }

      for (const [key, value] of Object.entries(filter)) {
        if (filter[key] && key !== "storeId") {
          sendData[key] = value;
        }
        if (filter[key] && key === "storeId") {
          sendData.storeIdList = [];
          sendData.storeIdList.push(value);
        }
      }

      this.sendFilter(sendData);
    },
    sendFilter(filter) {
      this.$emit("filter", filter);
    },
    searchUp(query) {
      this.$emit("search", query);
    }
  },
  mounted() {
    this.langDatePiker = CONST.LOCALE[this.$i18n.locale].toLowerCase();
  }
};
</script>

<style lang="scss">
#filterForm {
  .v-text-field__details {
    display: none;
  }
  [class*="col"] {
    padding: 8px 12px;
  }
}
</style>
