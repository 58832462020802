<template>
  <div class="item-info mb-3">
    <span class="d-inline-block mb-3">ID Заявки: {{ item.cpId }}</span>
    <span class="d-inline-block mb-3 ml-5" v-if="item.journalRef">
      Ref Заявки:
      <a
        :href="item.msbJournalHyperLink"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ item.journalRef }}
      </a>
    </span>

    <p class="mb-1">
      {{
        financingCR
          ? $t("orders.columns.estateSubject")
          : $t("orders.columns.leasingSubject")
      }}
    </p>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" v-for="head in headers" :key="head.text">
              {{ head.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="n in item.pledgeDetails" :key="n.pledgeDetailId">
            <td>{{ n.objectDetail.nameDesc }}</td>
            <td>{{ $t(`options.conditionTypes.${n.conditionType.value}`) }}</td>
            <td>{{ n.brand || `&mdash;` }}</td>
            <td>{{ n.model || `&mdash;` }}</td>
            <td>{{ n.cost | cost }}</td>
            <td>{{ n.discount }}%</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!--<span>{{ item }}</span>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrderInfo",
  props: ["item"],
  computed: {
    ...mapGetters(["getPointOfEntry"]),
    headers() {
      return [
        { text: this.$i18n.t("orders.columns.type") },
        { text: this.$i18n.t("orders.columns.condition") },
        { text: this.$i18n.t("orders.columns.brand") },
        { text: this.$i18n.t("orders.columns.model") },
        { text: this.$i18n.t("orders.columns.cost") },
        {
          text: this.$i18n.t("orders.columns.lowerPercent"),
          name: "lowerPercent"
        }
      ];
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    financingCR() {
      return this.item.program.value.includes("CR");
    }
  }
};
</script>
