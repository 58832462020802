<template>
  <v-list class="d-flex pa-0 item-actions">
    <!-- :disabled="!item.canBeSendToBank" -->
    <v-list-item
      id="send"
      class="mr-sm-4 mb-3 mb-sm-0"
      v-if="!isOnlySupport"
      :disabled="loading || !item.canBeSendToBank"
      @click="$emit('clickActions', ['sendToBank', item])"
    >
      <v-list-item-title class="d-flex align-center justify-center">
        <v-icon
          medium
          class="mr-2"
          :disabled="!item.canBeSendToBank"
          color="privat"
        >
          mdi-bank
        </v-icon>
        {{ `${$t("action.send")} в банк` }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item
      id="meeting"
      class="mr-sm-4 mb-3 mb-sm-0"
      v-if="!isOnlySupport && item.needCompleteMeet"
      @click="$emit('clickActions', ['reMakeMeeting', item])"
      :disabled="loading"
    >
      <v-list-item-title class="d-flex align-center justify-center">
        <v-progress-circular
          v-if="loading"
          indeterminate
          :size="20"
          :width="2"
          :value="75"
          color="privat"
        ></v-progress-circular>
        <v-icon
          medium
          class="mr-2"
          color="blue-grey darken-1"
          :disabled="loading"
        >
          mdi-account-group
        </v-icon>

        {{ $t("action.reMakeMeeting") }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item
      id="history"
      @click="$emit('clickActions', ['history', item])"
      :disabled="loading || !item.hasUaJournalState"
    >
      <v-list-item-title class="d-flex align-center justify-center">
        <v-icon
          medium
          class="mr-2"
          color="brown darken-1"
          :disabled="!item.hasUaJournalState"
        >
          mdi-history
        </v-icon>
        {{ $t("action.history") }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrderActions",
  props: {
    item: Object,
    loading: Boolean
  },
  computed: {
    ...mapGetters(["getUser"]),
    isOnlySupport() {
      const userGrants = this.getUser?.userGrants;

      return userGrants?.length === 1 && userGrants?.includes("SUPPORT_PB");
    }
  }
};
</script>
